import React, { useEffect, useState } from "react";
import "./finalBoard.scss";
import FinalScoreTable from "../../components/MainTable/FinalScoreTable";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_PATH, ROUTE_PATH } from "../../constants/route";
import axios from "axios";
const FinalBoard = () => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(10);
  const scoreBoardData = useSelector(
    (state) => state.authentication.scoreBoardData
  );

  useEffect(() => {
    if (timeLeft === 0) return;
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, [timeLeft]);

  const handlePlayAgain = () => {
    if (timeLeft === 0) {
      navigate(ROUTE_PATH?.JOIN_MATCH);
    }
  };

  const handleDownloadCsv = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL + API_PATH?.DOWNLOAD_CSV}`,
      { gameId: scoreBoardData?._id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
        responseType: "blob",
      }
    );

    if (response.data.error) {
      console.error(response.data.error);
    }

    const fileURL = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;

    const contentDisposition = response.headers["content-disposition"];
    let fileName = "download.xlsx";

    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
    }

    fileLink.setAttribute("download", fileName);
    fileLink.setAttribute("target", "_blank");
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  };

  return (
    <div className="final-score-board">
      <div>
        <div className="heading">Final Results</div>
        <div className="table-block">
          <FinalScoreTable
            players={scoreBoardData?.players}
            scoreBoardData={scoreBoardData}
          />
        </div>
      </div>
      {false ? (
        <div className="final-div">
          <div className="low-text">
            <ReportProblemIcon
              style={{
                color: "#EEFF34",
                width: "15px",
                height: "15px",
                marginRight: "2px",
              }}
            />{" "}
            Low wallet balance
          </div>
          <PrimaryButton
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              borderRadius: "60px",
              padding: "16px 40px",
            }}
          >
            Add Cash & Play Again
          </PrimaryButton>
        </div>
      ) : (
        <div className="buttons-div-wrap">
          {/* <PrimaryButton
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              borderRadius: "60px",
              padding: "16px",
              width: "100%",
            }}
            onClick={() => handleDownloadCsv()}
          >
            Download Results
          </PrimaryButton> */}
          <div className="button-div">
            <PrimaryButton
              sx={{
                fontWeight: "600",
                fontSize: "16px",
                borderRadius: "60px",
                padding: "16px",
                width: "70%",
              }}
              onClick={() => handlePlayAgain()}
            >
              Play Again {timeLeft ? `in 0:${timeLeft}s` : ""}
            </PrimaryButton>
            <SecondaryButton
              sx={{
                fontWeight: "600",
                fontSize: "16px",
                borderRadius: "60px",
                padding: "16px",
                width: "30%",
                marginLeft: "10px",
              }}
              onClick={() => navigate(ROUTE_PATH?.JOIN_MATCH)}
            >
              Cancel
            </SecondaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default FinalBoard;
