import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../../components/Button";
import goldWins from "../../assets/svg/goldWins.svg";
import silverWins from "../../assets/svg/silverWins.svg";
import players from "../../assets/svg/players.svg";
import "./joinMatch.scss";
import CustomTabs from "../../components/Tabs/CustomTabs";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { fetchListing } from "../../store/reducers/game/apiThunk";
import { updateListing } from "../../store/reducers/game";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/route";
import { useSocket } from "../../services/socketContext";
import {
  createPrivateGame,
  fetchUser,
} from "../../store/reducers/authentication/apiThunk";
import LockIcon from "@mui/icons-material/Lock";

export default function JoinMatch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useSocket();
  const userData = useSelector((state) => state.authentication.login);
  const [listing, setListing] = useState([]);
  const [listingNew, setListingNew] = useState([]);

  useEffect(() => {
    listingFun();
    dispatch(fetchUser());
  }, []);

  const listingFun = async () => {
    const response = await dispatch(fetchListing());
    const {
      payload: { data = [], status = false },
    } = response;
    if (status === true) {
      setListing(data);
      setListingNew(data);
      dispatch(updateListing(data));
    }
  };

  const joinGame = (gameId = "") => {
    const { data: { _id: userId = "" } = {} } = userData;
    const response = socket.emit("joinGame", { userId, gameId });
    const { connected = "" } = response;
    if (connected) {
      navigate(ROUTE_PATH.PLAYER_JOINING);
    }
  };

  const tabsData = [
    {
      title: "All",
      listingUpdate: () => {
        setListingNew(listing);
      },
    },
    {
      title: "3 Player",
      listingUpdate: () => {
        setListingNew(listing?.filter((item) => item?.numberOfPlayers === 3));
      },
    },
    {
      title: "4 Player",
      listingUpdate: () => {
        setListingNew(listing?.filter((item) => item?.numberOfPlayers === 4));
      },
    },
    {
      title: "5 Player",
      listingUpdate: () => {
        setListingNew(listing?.filter((item) => item?.numberOfPlayers === 5));
      },
    },
    {
      title: "6 Player",
      listingUpdate: () => {
        setListingNew(listing?.filter((item) => item?.numberOfPlayers === 6));
      },
    },
    {
      title: "7 Player",
      listingUpdate: () => {
        setListingNew(listing?.filter((item) => item?.numberOfPlayers === 7));
      },
    },
  ];

  const handlePrivateButton = async (gameId) => {
    const response = await dispatch(createPrivateGame({ gameId }));
    const { payload: { status = "", data: { _id = "" } = {} } = {} } = response;
    if (status) {
      navigate(ROUTE_PATH?.GAME_DETAIL + "/" + _id);
    }
  };

  return (
    <div className="joinMatch-page-wrap">
      <div className="header-wrap">
        <div className="heading">Match Center</div>
        <div className="avatar-wrap">
          <div className="addIcon">
            <AddIcon />
          </div>
          <div className="amount">₹{userData?.data?.coins || 0}</div>
          <Avatar alt={userData?.data?.name} src={userData?.data?.imageUrl} />
        </div>
      </div>
      <CustomTabs tabsData={tabsData} />
      <div className="cards-wrap">
        {listingNew?.length > 0 &&
          listingNew?.map((item, index) => {
            return (
              <div className="card-wrap" key={index}>
                <div className="left-content-wrap">
                  <div className="wins-wrap">
                    <img src={goldWins} alt="" />
                    <div>
                      <div className="title">Wins</div>
                      <div className="price">
                        ₹{item?.firstWinnerCoins || 0}
                      </div>
                    </div>
                  </div>
                  <div className="second-win">
                    <img src={silverWins} alt="" />
                    <div className="price">₹{item?.secondWinnerCoins || 0}</div>
                  </div>
                </div>
                <div className="right-content-wrap">
                  <div className="players-wrap">
                    <img src={players} alt="" />
                    <div>{item?.numberOfPlayers} Player</div>
                  </div>
                  <div className="joinMatch">Join match</div>
                  <div className="button-wrap">
                    <PrimaryButton onClick={() => joinGame(item._id)}>
                      ₹{item?.coinsPerPlayer}
                    </PrimaryButton>
                    <div onClick={() => handlePrivateButton(item._id)}>
                      <LockIcon sx={{ cursor: "pointer" }} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
