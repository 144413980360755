import { combineReducers } from '@reduxjs/toolkit';
import authentication from './authentication';
import loader from './loader';

const rootReducer = combineReducers({
  authentication,
  loader,
});

export default rootReducer;
