import { createSlice } from '@reduxjs/toolkit';
import { ToastNotifyError } from '../../../components/Toast/ToastNotify';
import {
  fetchListing,
} from './apiThunk';

const gameSlice = createSlice({
  name: 'game',
  initialState: {
    gameListing: {},
  },
  reducers: {
    updateListing: (state, action) => {
      state.gameListing = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListing.fulfilled, (state, action) => {
      state.gameListing = action.payload;
    });
    builder.addCase(fetchListing.rejected, (state, action) => {
      state.gameListing = action.payload;
      ToastNotifyError(action.error.message);
    });
  },
});

const { reducer } = gameSlice;
const selectorGame = (state) => state.game;
const {
  updateListing,
} = gameSlice.actions;
export {
  updateListing,
  selectorGame,
};

export default reducer;
