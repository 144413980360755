const ROUTE_PATH = {
  PAGE404: '*',
  LOGIN: '/login',
  JOIN_MATCH: '/join-match',
  SCORE_BOARD: '/score-board',
  FINAL_BOARD: '/final-board',
  LOADING: '/',
  PLAYER_JOINING: '/player-joining',
  GAME: '/game',
  GAME_DETAIL: '/game-detail',
  ADMINLOGIN: '/admin/login',
  ADMINDASHBOARD: '/admin/dashboard'
};

const API_PATH = {
  GOOGLE_LOGIN: '/api/v1/auth/google',
  LOGIN: '/admin-login',
  GAME_LISTING: '/api/v1/games',
  USER: '/api/v1/user',
  DOWNLOAD_CSV: '/api/v1/game/download-csv',
  CREATE_PRIVATE_GAME: '/api/v1/game/private-game',
  GAME_DETAIL: '/api/v1/game',
  ADMIN_LOGIN: '/api/v1/auth/admin/login',
  CONFIGURATION: '/api/v1/configuration',
  EDIT_CONFIGURATION: '/api/v1/configuration',
  UPLOAD_CSV: '/api/v1/jersey/upload',
  UPLOAD_COIN: '/api/v1/user/upload-coins',
  JERSEYS: '/api/v1/jerseys'
};

export { ROUTE_PATH, API_PATH };
