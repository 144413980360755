import PropTypes from 'prop-types';
import {
  Navigate,
} from 'react-router-dom';

function PublicRoute({ children }) {
  const auth = localStorage.getItem('auth');
  return auth ? <Navigate to="/join-match" /> : children;
}

PublicRoute.prototype = {
  children: PropTypes.element,
};

export default PublicRoute;
