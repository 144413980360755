import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGameDetail } from "../../store/reducers/authentication/apiThunk";
import { useNavigate, useParams } from "react-router-dom";
import "./gameDetail.scss";
import { PrimaryButton } from "../../components/Button";
import { Grid } from "@mui/material";
import { IconButton, Snackbar } from "@mui/material";
import { useSocket } from "../../services/socketContext";
import { ROUTE_PATH } from "../../constants/route";

export default function GameDetail() {
  const userData = useSelector((state) => state.authentication.login);
  const socket = useSocket();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [gameData, setGameData] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(window.location.toString());
  };

  const handleFetchGameDetail = async () => {
    const response = await dispatch(fetchGameDetail({ gameId: id }));
    const { payload: { status = "", data } = {} } = response;
    if (status) {
      setGameData(data);
    } else {
      navigate(ROUTE_PATH?.JOIN_MATCH);
    }
  };
  useEffect(() => {
    handleFetchGameDetail();
  }, []);

  const joinGame = (gameId = "") => {
    const { data: { _id: userId = "" } = {} } = userData;
    const response = socket.emit("joinGame", { userId, gameId });
    const { connected = "" } = response;
    if (connected) {
      navigate(ROUTE_PATH.PLAYER_JOINING);
    }
  };

  return (
    <div className="gameDetail">
      <div className="heading">Private Game</div>
      <div className="gameDetail-content">
        <Grid container>
          <Grid container xs={12} className="list-tab">
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              Game Fee
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              ₹{gameData?.coinsPerPlayer}
            </Grid>
          </Grid>
          <Grid container xs={12} className="list-tab">
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              First Winner Coins
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              ₹{gameData?.firstWinnerCoins}
            </Grid>
          </Grid>
          <Grid container xs={12} className="list-tab">
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              Second Winner Coins
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              ₹{gameData?.secondWinnerCoins}
            </Grid>
          </Grid>
          <Grid container xs={12} className="list-tab">
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              Number Of Players
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              {gameData?.numberOfPlayers}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <PrimaryButton
        sx={{
          width: "auto",
          fontWeight: "600",
          fontSize: "16px",
          borderRadius: "60px",
          marginTop: "20px",
        }}
        onClick={() => joinGame(gameData?._id)}
      >
        Join Game
      </PrimaryButton>
      <div className="url-wrap">
        <div className="url">
          {process.env.REACT_APP_URL + "/game-detail/" + id}
        </div>
        <div className="copy-wrap">
          <IconButton onClick={handleClick}>copy</IconButton>
          <Snackbar
            message="Copied to clipboard"
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={1000}
            onClose={() => setOpen(false)}
            open={open}
          />
        </div>
      </div>
    </div>
  );
}
