import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";
import { ROUTE_PATH } from "../constants/route";

function PrivateRoute({ children }) {
  const auth = localStorage.getItem("auth");
  const { id } = useParams();
  return auth ? (
    children
  ) : (
    <Navigate to={`${id ? ROUTE_PATH.LOGIN + "/" + id : ROUTE_PATH.LOGIN}`} />
  );
}

PrivateRoute.prototype = {
  children: PropTypes.element,
};

export default PrivateRoute;
