import React from "react";
import { Avatar, Grid, Typography } from "@mui/material";
import "./playerTable.scss";
import WinnerOne from "../../assets/images/1_Winner.png";
import WinnerTwo from "../../assets/images/2_Winner.png";
import Crown from "../../assets/images/crown.png";

const FinalScoreTable = ({ players = [], scoreBoardData = {} }) => {
  return (
    <div className="table-container">
      <Grid container className="header">
        <Grid item xs={3}>
          <Typography>Player</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Name</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Final Score</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>Winnings</Typography>
        </Grid>
      </Grid>
      <div className="player-container">
        {players.map((player, index) => (
          <div
            key={index}
            className={`player-div ${
              index === 0
                ? "green-bg first-row"
                : players.length - 1 === index
                ? "red-bg"
                : ""
            }`}
          >
            <Grid container className="player-row">
              {index === 0 ? (
                <Grid item xs={2} className="player-info">
                  <img src={Crown} alt="crown" className="crown-img" />
                  <div className="avatar-container">
                    <Avatar
                      src={player?.user?.imageUrl}
                      alt={player?.user?.name}
                      className="avatar"
                    />
                    <Typography className="player-name">
                      {player?.user?.name}
                    </Typography>
                  </div>
                  <img src={WinnerOne} alt="winner_1" className="one-img" />
                </Grid>
              ) : index === 1 ? (
                <Grid item xs={2} className="player-info">
                  <div className="avatar-container">
                    <Avatar
                      src={player?.user?.imageUrl}
                      alt={player?.user?.name}
                      className="avatar"
                    />
                    <Typography className="player-name">
                      {player?.user?.name}
                    </Typography>
                  </div>
                  <img src={WinnerTwo} alt="winner_2" className="one-img" />
                </Grid>
              ) : (
                <Grid item xs={2} className="player-info">
                  <div className="avatar-container">
                    <Avatar
                      src={player?.user?.imageUrl}
                      alt={player?.user?.name}
                      className="avatar"
                    />
                    <Typography className="player-name">
                      {player?.user?.name}
                    </Typography>
                  </div>
                </Grid>
              )}

              <Grid item xs={4} className="points-earned">
                <Typography className="points">
                  {player?.user?.name?.split(" ")[0]}
                </Typography>
              </Grid>

              <Grid item xs={2} className="points-earned">
                <Typography className="points">
                  {player?.score}
                  <span
                    style={{
                      color: "rgba(255, 255, 255, 0.6)",
                      fontSize: "12px",
                    }}
                  >
                    pts
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={2} className="balls-earned">
                <Typography className="balls">
                  ₹
                  {index === 0
                    ? parseFloat(scoreBoardData?.firstWinnerCoins).toFixed(2)
                    : index === 1
                    ? parseFloat(scoreBoardData?.secondWinnerCoins).toFixed(2)
                    : "0.00"}
                </Typography>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FinalScoreTable;
