import React, { useEffect } from "react";
import './scoreBoard.scss'
import PlayerScoreTable from "../../components/MainTable/PlayerScoreTable";
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/route";

const ScoreBoard = () => {
    const navigate = useNavigate();
    const scoreBoardData = useSelector((state) => state.authentication.scoreBoardData);
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate(ROUTE_PATH?.FINAL_BOARD);
        }, 10000);
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="score-board">
            <div>
                <div className="heading">Score board</div>
                <div className="table-block">
                    <PlayerScoreTable players={scoreBoardData?.players}/>
                </div>
            </div>
            <div className="loading-box">
                <CircularProgress sx={{
                    color: 'white',
                }} size={20}/>
                <div className="loading-text">Analyzing final results</div>
            </div>
        </div>
    );
}

export default ScoreBoard;