import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function Loader() {
  // this is dummy loader change when design part ready
  return (
    <div>
      <Backdrop
        sx={{
          color: '#7C98F5',
          zIndex: 99999999,
        }}
        open
      >
        <CircularProgress style={{
          color: '#283955',
        }}
        />
      </Backdrop>
    </div>
  );
}

export default Loader;
