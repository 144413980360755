import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#4f4f4f99',
    },
    secondary: {
      main: '#1a1a1a99',
    },
    customColor: {
      primaryWhite: '#FFFFFF',
      primaryYellow: '#F69824',
      primaryTransparent: 'transparent',
    },
  },
});
