import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchConfiguration, editConfiguration, uploadXlsx, fetchLogout, uploadCoins } from "../../store/reducers/authentication/apiThunk";
import { useNavigate } from 'react-router-dom';
import './AdminDashboard.css';
import deleteIcon from "../../assets/delete-icon.png";

const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState('configuration');
    const [isEditing, setIsEditing] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [uploadMessage, setUploadMessage] = useState('');
    const [isUploaded, setIsUploaded] = useState(false);
    const [uploadedCoinsFile, setUploadedCoinsFile] = useState(null);
    const [coinsUploadMessage, setCoinsUploadMessage] = useState('');
    const [isCoinsUploaded, setIsCoinsUploaded] = useState(false);
    const [configuration, setConfiguration] = useState({
        entryFee: [],
        firstWinningPercent: 0,
        secondWinningPercent: 0,
        platformCommissionPercent: 0,
        numberOfTokens: 0,
        totalNumberOfCards: 0,
        initialNumberOfCard: 0,
        numberOfCardsToRemove: 0,
    });

    const [entryFee, setEntryFee] = useState([]);
    const [newEntryFeeValue, setNewEntryFeeValue] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fetchConfigData = async () => {
        try {
            const response = await dispatch(fetchConfiguration()).unwrap();
            if (response && response.data) {
                setConfiguration(response.data);
                setEntryFee(response.data.entryFee);
            } else {
                setConfiguration({
                    entryFee: [],
                    firstWinningPercent: 0,
                    secondWinningPercent: 0,
                    platformCommissionPercent: 0,
                    numberOfTokens: 0,
                    totalNumberOfCards: 0,
                    initialNumberOfCard: 0,
                    numberOfCardsToRemove: 0
                });
            }
        } catch (error) {
            console.error("Error fetching configuration:", error);
            setConfiguration({
                entryFee: [],
                firstWinningPercent: 0,
                secondWinningPercent: 0,
                platformCommissionPercent: 0,
                numberOfTokens: 0,
                totalNumberOfCards: 0,
                initialNumberOfCard: 0,
                numberOfCardsToRemove: 0
            });
        }
    };

    useEffect(() => {
        fetchConfigData();
    }, [dispatch]);


    const handleCoinsFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedCoinsFile(file);
        }
    };


    const handleCoinsFileUpload = async () => {
        if (!uploadedCoinsFile) {
            setCoinsUploadMessage('Please select a file to upload.');
            setIsCoinsUploaded(false);
            return;
        }

        const formData = new FormData();
        formData.append('file', uploadedCoinsFile);

        try {
            const response = await dispatch(uploadCoins(formData)).unwrap();
            setCoinsUploadMessage('Coins uploaded successfully!');
            setIsCoinsUploaded(true);
            setUploadedCoinsFile(null);
        } catch (error) {
            setCoinsUploadMessage('Failed to upload the coins.');
            setIsCoinsUploaded(false);
            console.error("Error uploading coins:", error);
        }
    };


    const handleTabClick = (tab) => {
        if (tab === 'logout') {
            handleLogout();
        } else {
            setActiveTab(tab);
            setIsEditing(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setConfiguration((prevConfig) => ({
            ...prevConfig,
            [name]: value,
        }));
    };

    // const handleEntryFeeChange = (e) => {
    //     const value = e.target.value;
    //     const entryFeeArray = value
    //         .split(',')
    //         .map(fee => parseInt(fee.trim(), 10))
    //         .filter(fee => !isNaN(fee) && fee > 0);


    //     setConfiguration(prevConfig => ({
    //         ...prevConfig,
    //         entryFee: entryFeeArray,
    //     }));
    // };

    // Handle changing values in the input fields
    const handleChange = (index, e) => {
        const newValues = [...entryFee];
        newValues[index] = Number(e.target.value);
        setEntryFee(newValues);
    };

    // Remove value from the array
    const handleRemove = (index) => {
        const newValues = entryFee.filter((_, i) => i !== index);
        setEntryFee(newValues);
    };

    // Add new value to the array
    const handleAdd = () => {
        if (newEntryFeeValue) {
            setEntryFee([...entryFee, Number(newEntryFeeValue)]);
            setNewEntryFeeValue(""); // Clear the input field
        }
    };

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const handleSave = async () => {
        if (configuration.entryFee.length === 0) {
            alert("Please provide at least one entry fee.");
            return;
        }

        // Create a copy of the configuration object without the _id field
        const { _id, createdAt, entryFeeObject, updatedAt, __v, ...configWithFieldsToSave } = configuration;
        console.log("entryFee--->", entryFee);
        configWithFieldsToSave.entryFee = entryFee;

        try {
            console.log("configuration--->", configWithFieldsToSave);
            await dispatch(editConfiguration(configWithFieldsToSave)).unwrap();
            await fetchConfigData();

            setIsEditing(false);
        } catch (error) {
            console.error("Error saving configuration:", error);
        }
    };

    const handleLogout = async () => {
        try {
            await dispatch(fetchLogout()).unwrap();
            localStorage.removeItem('auth');
            navigate('/admin/login');
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setUploadedFile(file);
    };

    const handleFileUpload = async () => {
        if (!uploadedFile) {
            setUploadMessage('Please select a file to upload.');
            setIsUploaded(false);
            return;
        }

        const formData = new FormData();
        formData.append('file', uploadedFile);

        try {
            const response = await dispatch(uploadXlsx(formData)).unwrap();
            setUploadMessage('File uploaded successfully!');
            setIsUploaded(true);
            setUploadedFile(null);
        } catch (error) {
            setUploadMessage('Failed to upload the file.');
            setIsUploaded(false);
            console.error("Error uploading file:", error);
        }
    };

    return (
        <div className="admin-dashboard">
            <aside className="sidebar">
                <h2>Admin Dashboard</h2>
                <ul>
                    <li onClick={() => handleTabClick('configuration')} className={activeTab === 'configuration' ? 'active' : ''}>
                        Configuration
                    </li>
                    <li onClick={() => handleTabClick('uploadCsv')} className={activeTab === 'uploadCsv' ? 'active' : ''}>
                        Upload Jersey
                    </li>
                    <li onClick={() => setActiveTab('uploadCoins')} className={activeTab === 'uploadCoins' ? 'active' : ''}>Upload User Coins</li>
                    <li onClick={() => handleTabClick('token')} className={activeTab === 'token' ? 'active' : ''}>
                        Token
                    </li>
                    <li onClick={() => handleTabClick('logout')} className={activeTab === 'logout' ? 'active' : ''}>
                        Logout
                    </li>
                </ul>
            </aside>

            <main className="main-content">
                {activeTab === 'configuration' && (
                    <div className="tab-content">
                        <div className="title">Configuration</div>

                        {!isEditing && (
                            <div className="config-info">
                                <p><strong>Entry Fees:</strong> {configuration.entryFee.join(', ')}</p>
                                <p><strong>First Winning Percent:</strong> {configuration.firstWinningPercent}</p>
                                <p><strong>Second Winning Percent:</strong> {configuration.secondWinningPercent}</p>
                                <p><strong>Platform Commission Percent:</strong> {configuration.platformCommissionPercent}</p>
                                <p><strong>Number Of Tokens:</strong> {configuration.numberOfTokens}</p>
                                <p><strong>Total Number Of Cards:</strong> {configuration.totalNumberOfCards}</p>
                                <p><strong>Initial Number Of Card:</strong> {configuration.initialNumberOfCard}</p>
                                <p><strong>Total Number Of Cards to remove:</strong> {configuration.numberOfCardsToRemove}</p>

                                <button onClick={toggleEdit} className="edit-btn">Edit List</button>
                            </div>
                        )}

                        {isEditing && (
                            <form className="config-form">
                                <label>
                                    Entry Fees (comma-separated):
                                    {entryFee.map((value, index) => (
                                        <div key={index} className='remove-input-wrap'>
                                            <input
                                                type="number"
                                                value={value}
                                                onChange={(e) => handleChange(index, e)}
                                            />
                                            <button className='remove-btn' type="button" onClick={() => handleRemove(index)}>
                                                <img src={deleteIcon} />
                                            </button>
                                        </div>
                                    ))}

                                    <div className='add-input-wrap'>
                                        <input
                                            type="number"
                                            value={newEntryFeeValue}
                                            onChange={(e) => setNewEntryFeeValue(e.target.value)}
                                            placeholder="Add new value"
                                        />
                                        <button className='add-btn' type="button" onClick={handleAdd}>Add</button>
                                    </div>
                                </label>
                                <label>
                                    First Winning Percent:
                                    <input
                                        type="number"
                                        name="firstWinningPercent"
                                        value={configuration.firstWinningPercent}
                                        onChange={handleInputChange}
                                    />
                                </label>
                                <label>
                                    Second Winning Percent:
                                    <input
                                        type="number"
                                        name="secondWinningPercent"
                                        value={configuration.secondWinningPercent}
                                        onChange={handleInputChange}
                                    />
                                </label>
                                <label>
                                    Platform Commission Percent:
                                    <input
                                        type="number"
                                        name="platformCommissionPercent"
                                        value={configuration.platformCommissionPercent}
                                        onChange={handleInputChange}
                                    />
                                </label>
                                <label>
                                    Number of Tokens:
                                    <input
                                        type="number"
                                        name="numberOfTokens"
                                        value={configuration.numberOfTokens}
                                        onChange={handleInputChange}
                                    />
                                </label>
                                <label>
                                    Total Number Of Cards:
                                    <input
                                        type="number"
                                        name="totalNumberOfCards"
                                        value={configuration.totalNumberOfCards}
                                        onChange={handleInputChange}
                                    />
                                </label>
                                <label>
                                    Initial Number Of Card:
                                    <input
                                        type="number"
                                        name="initialNumberOfCard"
                                        value={configuration.initialNumberOfCard}
                                        onChange={handleInputChange}
                                    />
                                </label>
                                <label>
                                    Total Number Of Cards to remove:
                                    <input
                                        type="number"
                                        name="numberOfCardsToRemove"
                                        value={configuration.numberOfCardsToRemove}
                                        onChange={handleInputChange}
                                    />
                                </label>

                                <button type="button" className="save-btn" onClick={handleSave}>Save</button>
                            </form>
                        )}
                    </div>
                )}

                {activeTab === 'uploadCsv' && (
                    <div className="tab-content">
                        <div className="title">Upload Jersey</div>
                        <div className="upload-section">
                            <input
                                type="file"
                                accept=".xlsx"
                                onChange={handleFileChange}
                            />
                            {uploadedFile && !uploadMessage && <p>Selected File: {uploadedFile.name}</p>} {/* Show filename only if no message is displayed */}
                            <button className="upload-btn" onClick={handleFileUpload}>Upload Jersey</button>

                            {uploadMessage && (
                                <p className={isUploaded ? 'success-message' : 'error-message'}>
                                    {uploadMessage}
                                </p>
                            )}
                        </div>
                    </div>
                )}

                {activeTab === 'uploadCoins' && (
                    <div className="tab-content">
                        <div className="title">Upload User Coins</div>
                        <div className="upload-section">
                            <input
                                type="file"
                                accept=".xlsx"
                                onChange={handleCoinsFileChange}
                            />
                            {uploadedCoinsFile && !coinsUploadMessage && (
                                <p>Selected File: {uploadedCoinsFile.name}</p>
                            )}
                            <button className="upload-btn" onClick={handleCoinsFileUpload}>Upload Coins</button>

                            {coinsUploadMessage && (
                                <p className={isCoinsUploaded ? 'success-message' : 'error-message'}>
                                    {coinsUploadMessage}
                                </p>
                            )}
                        </div>
                    </div>
                )}

                {activeTab === 'token' && (
                    <div className="tab-content">
                        <h1>Your token</h1>
                        <div className='token-wrap'>
                            {localStorage.getItem('auth')}
                        </div>
                    </div>
                )}

                {activeTab === 'logout' && (
                    <div className="tab-content">
                        <h1>You have logged out successfully!</h1>
                    </div>
                )}
            </main>
        </div>
    );
};

export default AdminDashboard;
