import React from "react";
import LinearLoader from "../../components/Loader/LinearLoader/LinearLoader";
import logo from "../../assets/svg/logo.svg";
import "./loadingPage.scss";
import { ROUTE_PATH } from "../../constants/route";

export default function Loading() {
  return (
    <div className="loading-page-wrap">
      {/* <div></div> */}
      {/* <img src={logo} alt="" srcset="" /> */}
      <div className="loader-wrap">
        <LinearLoader navigateRoute={ROUTE_PATH.LOGIN} />
      </div>
    </div>
  );
}
