import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './AdminLogin.css';
import { fetchAdminLogin } from "../../store/reducers/authentication/apiThunk";
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../constants/route';

const AdminLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ email: '', password: '' });

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleLogin = async (e) => {
        e.preventDefault();
        let valid = true;
        let emailError = '';
        let passwordError = '';

        if (!email) {
            emailError = 'Email is required';
            valid = false;
        } else if (!emailPattern.test(email)) {
            emailError = 'Please enter a valid email address';
            valid = false;
        }

        if (!password) {
            passwordError = 'Password is required';
            valid = false;
        } else if (password.length < 6) {
            passwordError = 'Password must be at least 6 characters long';
            valid = false;
        }

        setErrors({ email: emailError, password: passwordError });

        if (valid) {
            const data = { email, password };
            try {
                await dispatch(fetchAdminLogin(data)).then((val) => val);
                await navigate(ROUTE_PATH.ADMINDASHBOARD)
            } catch (error) {
                console.error('Admin login failed:', error);
            }
        }
    };

    return (
        <div className="admin-login-container">
            <h2>Admin Login</h2>
            <form onSubmit={handleLogin} className="admin-login-form">
                <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className={errors.email ? 'error-input' : ''}
                    />
                    {errors.email && <span className="error-message">{errors.email}</span>}
                </div>

                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                        className={errors.password ? 'error-input' : ''}
                    />
                    {errors.password && <span className="error-message">{errors.password}</span>}
                </div>

                <button type="submit" className="admin-login-button">Login</button>
            </form>
        </div>
    );
};

export default AdminLogin;
