import { createAsyncThunk } from '@reduxjs/toolkit';
import create from '../../../services/api';
import { API_PATH } from '../../../constants/route';
import { updateLoading } from '../loader';

const fetchListing = createAsyncThunk('game/listing', async (data, { dispatch, getState }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(API_PATH.GAME_LISTING);
    dispatch(updateLoading(false));
    return response.data;
  } catch (err) {
    return err;
  }
});

export {
  fetchListing,
};
