import React, { useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect } from "react";
import "./linearLoader.scss";
import { useNavigate } from "react-router-dom";

export default function LinearLoader({ navigateRoute = "/" }) {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          navigate(navigateRoute);
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Box sx={{ width: "100%" }} className="linear-loader-wrap">
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
}
