import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import './button.scss';

export const PrimaryButton = styled(Button)({
  background: 'radial-gradient(67.72% 162.24% at 30.61% 14.82%, #B6FF93 0%, #3DBE00 100%)',
  backgroundColor: '#283955',
  borderRadius: '32px',
  textTransform: 'none',
  width: '100%',
  letterSpacing: '-4%',
  textAlign: 'center',
  fontWeight: '800',
  fontSize: '18px',
  lineHeight: '21.48px',
  color: '#2D4A89',
  border: 'none',
  whiteSpace: 'nowrap',
  boxShadow: '-1px -2px 4px 0px #4BA91E inset',
  padding: '8px 14px',
  '&:hover': {
    backgroundColor: '#283955',
  },
});

export const SecondaryButton = styled(Button)({
  background: '#FFFFFF',
  borderRadius: '32px',
  textTransform: 'none',
  width: '100%',
  letterSpacing: '-4%',
  textAlign: 'center',
  fontWeight: '800',
  fontSize: '18px',
  lineHeight: '21.48px',
  color: '#2D4A89',
  boxShadow: '1.02px 2.04px 1.02px 0px #FFFFFF66 inset',
  border: 'none',
  whiteSpace: 'nowrap',
  padding: '8px 14px',
  gap: '4px',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
});

export const TrinaryButton = styled(Button)({
  background: '#999999',
  backgroundColor: '#999999',
  borderRadius: '6px',
  height: '45px',
  textTransform: 'none',
  width: '100%',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '22px',
  color: '#FFFFFF',
  fontFamily: 'Montserrat, sans-serif !important',
  boxShadow: 'none',
  border: 'none',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: '#999999',
  },
});
