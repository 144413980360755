import * as React from "react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import "./drawer.scss";
import cardsCount from "../../assets/svg/cardsCount.svg";
import CrossIcon from "../../assets/images/cross.png";
import passScoreBall from "../../assets/images/passScoreBall.png";
import { useSelector } from "react-redux";
import cardJsonData from "../../pages/game/playerCard.json";
const StyledComponent = styled("div")(({ theme }) => ({
  backgroundColor: "transparent",
  position: "relative",
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: "#F6F9FF",
  borderRadius: "25px 25px 0 0",
  marginTop: "10px",
  ...theme.applyStyles("dark", {
    backgroundColor: grey[800],
  }),
  paddingBottom: "17px",
}));

function SwipeableEdgeDrawer({ open, toggleDrawer, userInfo }) {
  const userData = useSelector((state) => state.authentication.login);
  const { data: { _id: userId = "" } = {} } = userData;
  const sumPoints = userInfo?.cards?.length
    ? processArray(userInfo?.cards)
        .map((subArray) => subArray[0])
        .reduce((acc, curr) => acc + curr, 0)
    : 0;

  function processArray(arr) {
    arr?.sort((a, b) => a - b);
    let result = [];
    let currentRange = [arr[0]];

    for (let i = 1; i < arr.length; i++) {
      if (arr[i] === arr[i - 1] + 1) {
        currentRange.push(arr[i]);
      } else {
        result.push(currentRange);
        currentRange = [arr[i]];
      }
    }
    result.push(currentRange);
    return result;
  }
  return (
    <div>
      <SwipeableDrawer
        // container={container}
        anchor="bottom"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        className="drawer-box"
      >
        <StyledComponent>
          <div style={{ textAlign: "center" }}>
            <img
              src={CrossIcon}
              alt="cross"
              style={{ height: "36px", width: "36px" }}
              onClick={() => toggleDrawer(false)}
            />
          </div>
          <StyledBox>
            <div className="header">
              <div className="name-head">
                {userInfo?.user?._id === userId
                  ? "Your cards"
                  : userInfo?.user?.name
                  ? (userInfo?.user?.name).split(" ")[0] + "'S CARDS"
                  : "PLAYER'S CARD"}
              </div>
              {userInfo?.user?._id === userId ? (
                <div className="footer-container">
                  <div className="footer-wrap">
                    <div className="points">
                      <span className="points-val">{sumPoints || 0}</span> pts
                    </div>
                    <div className="totalCardsWrap">
                      {userInfo?.cards?.length || 0}
                    </div>
                    <img src={cardsCount} alt="" className="cardsCount" />
                  </div>
                  <div className="points points-val ">
                    {userInfo?.chips}
                    <img src={passScoreBall} alt="" className="passScoreBall" />
                  </div>
                </div>
              ) : (
                <div className="footer-wrap">
                  <div className="points">
                    <span className="points-val">{sumPoints || 0}</span> pts
                  </div>
                  <div className="totalCardsWrap">
                    {userInfo?.cards?.length || 0}
                  </div>
                  <img src={cardsCount} alt="" className="cardsCount" />
                </div>
              )}
            </div>
            <div className="main-box">
              {userInfo?.cards?.length > 0 &&
                processArray(userInfo?.cards)?.map((item) => {
                  if (item?.length === 1) {
                    return (
                      <img
                        src={cardJsonData[item[0] - 1]?.image}
                        alt=""
                        style={{
                          width: "96px",
                          height: "142px",
                        }}
                      />
                    );
                  } else {
                    return (
                      <div style={{ position: "relative" }}>
                        <img
                          src={cardJsonData[item[0] - 1]?.image}
                          alt=""
                          style={{
                            width: "96px",
                            height: "142px",
                          }}
                        />
                        <img
                          src={cardJsonData[item[item?.length - 1] - 1]?.image}
                          alt=""
                          style={{
                            width: "39px",
                            height: "56px",
                            position: "absolute",
                            bottom: "-3%",
                            right: "-10%",
                          }}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </StyledBox>
        </StyledComponent>
      </SwipeableDrawer>
    </div>
  );
}

export default SwipeableEdgeDrawer;
