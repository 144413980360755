import React from 'react'
import './index.css';

export default function GameWrap({ children }) {
    return (
        <div className='game-wrapper '>
            {children}
        </div>
    )
}
