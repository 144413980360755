import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./customTabs.scss";

export default function CustomTabs({ tabsData = [] }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="customTabs-wrap">
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
      >
        {tabsData.map((item) => {
          return <Tab label={item.title} key={item.title} onClick={item?.listingUpdate}/>;
        })}
      </Tabs>
    </Box>
  );
}
