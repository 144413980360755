import React, { useState } from "react";
import "./login.scss";
import loginImage from "../../assets/login-image.png";
import GoogleButton from "../../assets/svg/google-login.svg";
import { useGoogleLogin } from "@react-oauth/google";
import {
  fetchLogin,
  fetchUser,
} from "../../store/reducers/authentication/apiThunk";
import { useDispatch } from "react-redux";
import { ROUTE_PATH } from "../../constants/route";
import { useNavigate, useParams } from "react-router-dom";
import { updatelogin } from "../../store/reducers/authentication";
function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: paramId } = useParams();

  const loginFun = async (id) => {
    const response = await dispatch(fetchLogin({ token: id })).then(
      (val) => val
    );
    const {
      payload: { status, data = {} },
    } = response;
    if (status === true) {
      // await dispatch(updatelogin(data));
      dispatch(fetchUser());
      if (paramId) {
        navigate(ROUTE_PATH.GAME_DETAIL + "/" + paramId);
      } else {
        navigate(ROUTE_PATH.JOIN_MATCH);
      }
    }
  };
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => loginFun(codeResponse?.access_token),
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <div className="login-wrap">
      <img src={loginImage} alt="" srcSet="" className="loginImage" />
      <div className="box-content">
        <div className="welcome-heading">Welcome to JerseySure</div>
        <div className="login-subheading">Log in to join the excitement</div>
        <img
          src={GoogleButton}
          alt="login-button"
          style={{ width: "100%", height: "60px" }}
          onClick={() => login()}
        />
      </div>
    </div>
  );
}

export default Login;
