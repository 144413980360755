import React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import './playerTable.scss';
import BallImg from '../../assets/images/ball.png'


const PlayerScoreTable = ({ players }) => {
  return (
    <div className="table-container">
      <Grid container className="header">
        <Grid item xs={4}><Typography>Player</Typography></Grid>
        <Grid item xs={4}><Typography>Pts Earned</Typography></Grid>
        <Grid item xs={4}><Typography>Balls Earned</Typography></Grid>
      </Grid>
      <div className="player-container">
        {players.map((player, index) => (
          <div key={index} className='player-div'>
            <Grid container className="player-row">
              <Grid item xs={4} className="player-info">
                <div className="avatar-container">
                  <Avatar src={player?.user?.imageUrl} alt={player?.user?.name} className="avatar" />
                  <Typography className="player-name">{player?.user?.name}</Typography>
                </div>
              </Grid>
              <Grid item xs={4} className="points-earned">
                <Typography className="points">{player?.score + player?.chips}<span style={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: '12px' }}>pts</span></Typography>
              </Grid>
              <Grid item xs={4} className="balls-earned">
                <img src={BallImg} alt="ball" className="ball-icon" />
                <Typography className="balls"><span style={{ fontSize: '12px', fontWeight: '400' }}>x </span>{player?.chips}</Typography>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlayerScoreTable;