import {
  RouterProvider,
} from 'react-router-dom';
import { connect } from 'react-redux';
import './App.scss';
import { ThemeProvider } from '@mui/material/styles';
import routes from './routes';
import { theme } from './theme';
import Loader from './components/Loader';

function App(props) {
  const {
    updateLoading:
    {
      isLoading = false,
    } = {},
  } = props;
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {isLoading ? <Loader is_loading={isLoading} /> : null}
        <RouterProvider router={routes} />
      </div>
    </ThemeProvider>
  );
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => ({
  updateLoading: state.loader,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
