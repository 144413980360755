import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import create from '../../../services/api';
import { API_PATH } from '../../../constants/route';
import { updateLoading } from '../loader';

const fetchLogout = createAsyncThunk('authentication/logout', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.LOGOUT, data);
    dispatch(updateLoading(false));
    localStorage.clear();
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchLogin = createAsyncThunk('authentication/login', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.GOOGLE_LOGIN, data);
    dispatch(updateLoading(false));
    const { data: { data: { token }, status } = {} } = response;
    if (status) {
      localStorage.setItem('auth', token);
    }
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchUser = createAsyncThunk('authentication/user', async (data, { dispatch, getState }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(API_PATH.USER, data);
    dispatch(updateLoading(false));
    return response.data;
  } catch (err) {
    return err;
  }
});

const createPrivateGame = createAsyncThunk('authentication/createPrivateGame', async (data, { dispatch, getState }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.CREATE_PRIVATE_GAME, data);
    dispatch(updateLoading(false));
    return response.data;
  } catch (err) {
    return err;
  }
});

const fetchGameDetail = createAsyncThunk('authentication/fetchGameDetail', async (data, { dispatch, getState }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.GAME_DETAIL, data);
    dispatch(updateLoading(false));
    return response.data;
  } catch (err) {
    return err;
  }
});

const fetchAdminLogin = createAsyncThunk('authentication/adminLogin', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.ADMIN_LOGIN, data);
    dispatch(updateLoading(false));
    const { data: { data: { token }, status } = {} } = response;
    if (status) {
      localStorage.setItem('auth', token);
    }
    return response?.data;
  } catch (err) {
    dispatch(updateLoading(false));
    return err;
  }
});

const fetchConfiguration = createAsyncThunk('authentication/fetchConfiguration', async (data, { dispatch, getState }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(API_PATH.CONFIGURATION, data);
    dispatch(updateLoading(false));
    return response.data;
  } catch (err) {
    return err;
  }
});
const fetchAllJerseys = createAsyncThunk('authentication/fetchAllJerseys', async (data, { dispatch, getState }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(API_PATH.JERSEYS, data);
    dispatch(updateLoading(false));
    return response.data;
  } catch (err) {
    return err;
  }
});
const editConfiguration = createAsyncThunk('authentication/editConfiguration', async (updatedConfig, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(`${API_PATH.EDIT_CONFIGURATION}`, updatedConfig);
    dispatch(updateLoading(false));
    return response.data;
  } catch (err) {
    dispatch(updateLoading(false));
    return err;
  }
});

const uploadXlsx = createAsyncThunk('authentication/uploadXlsx', async (formData, { dispatch }) => {
  console.log('FormData:', formData);
  try {
    const token = localStorage.getItem('auth');
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}${API_PATH.UPLOAD_CSV}`, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`

      }
    });
    console.log('response:', response);
  } catch (error) {
    console.log('Error:', error);
    throw new Error('Failed to upload XLSX file.');
  }
});


const uploadCoins = createAsyncThunk('authentication/uploadCoins', async (formData, { dispatch }) => {
  console.log('FormData:', formData);
  try {
    const token = localStorage.getItem('auth');
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}${API_PATH.UPLOAD_COIN}`, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    console.log('response:', response);
  } catch (error) {
    console.log('Error:', error);
    throw new Error('Failed to upload XLSX file.');
  }
});


export {
  fetchLogin,
  fetchLogout,
  fetchUser,
  createPrivateGame,
  fetchGameDetail,
  fetchAdminLogin,
  fetchConfiguration,
  editConfiguration,
  uploadXlsx,
  uploadCoins,
  fetchAllJerseys
};
