import { createBrowserRouter } from "react-router-dom";
import { ROUTE_PATH } from "../constants/route";
import PrivateRoute from "./PrivateRoute";
import Login from "../pages/login";
import PublicRoute from "./PublicRoute";
import JoinMatch from "../pages/joinMatch/JoinMatch";
import ScoreBoard from "../pages/scoreBoard/ScoreBoard";
import FinalBoard from "../pages/finalBoard/FinalBoard";
import Loading from "../pages/loadingPage/Loading";
import Game from "../pages/game/Game";
import GameDetail from "../pages/gameDetail/GameDetail";
import PlayerJoining from "../pages/playerJoining/PlayerJoining";
import AdminLogin from "../pages/AdminLogin/AdminLogin";
import GameWrap from "../pages/GameWrap";
import AdminDashboard from "../pages/AdminDashboard/AdminDashboard";
// import Customer from '../pages/customer';

const routes = createBrowserRouter([
  {
    path: ROUTE_PATH.LOGIN,
    element: (
      <PublicRoute>
        <GameWrap>
          <Login />
        </GameWrap>
      </PublicRoute>
    ),
  },
  {
    path: ROUTE_PATH.LOGIN + "/:id",
    element: (
      <PublicRoute>
        <GameWrap>
          <Login />
        </GameWrap>
      </PublicRoute>
    ),
  },
  {
    path: ROUTE_PATH.LOADING,
    element: (
      <PublicRoute>
        <GameWrap>
          <Loading />
        </GameWrap>
      </PublicRoute>
    ),
  },
  {
    path: ROUTE_PATH.JOIN_MATCH,
    element: (
      <PrivateRoute>
        <GameWrap>
          <JoinMatch />
        </GameWrap>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTE_PATH.SCORE_BOARD,
    element: (
      <PrivateRoute>
        <GameWrap>
          <ScoreBoard />
        </GameWrap>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTE_PATH.FINAL_BOARD,
    element: (
      <PrivateRoute>
        <GameWrap>
          <FinalBoard />
        </GameWrap>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTE_PATH.PLAYER_JOINING,
    element: (
      <PrivateRoute>
        <GameWrap>
          <PlayerJoining />
        </GameWrap>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTE_PATH.GAME,
    element: (
      <PrivateRoute>
        <GameWrap>
          <Game />
        </GameWrap>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTE_PATH.GAME_DETAIL + "/:id",
    element: (
      <PrivateRoute>
        <GameWrap>
          <GameDetail />
        </GameWrap>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTE_PATH.ADMINLOGIN,
    element: (
      <PublicRoute>
        <AdminLogin />
      </PublicRoute>
    ),
  },
  {
    path: ROUTE_PATH.ADMINDASHBOARD,
    element: (
      <PrivateRoute>
        <AdminDashboard />
      </PrivateRoute>
    ),
  },
  // {
  //   path: ROUTE_PATH.CUSTOMER,
  //   element: <PrivateRoute><Customer /></PrivateRoute>,
  // },
]);

export default routes;
