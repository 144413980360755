import { createSlice } from '@reduxjs/toolkit';
import { ToastNotifyError } from '../../../components/Toast/ToastNotify';
import {
  fetchLogin,
  fetchUser,
  fetchAllJerseys,
} from './apiThunk';

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    login: {},
    scoreBoardData: {},
    jerseysList: {}
  },
  reducers: {
    updatelogin: (state, action) => {
      state.login = action.payload;
    },
    updateScoreBoard: (state, action) => {
      state.scoreBoardData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      state.login = action.payload;
    });
    builder.addCase(fetchLogin.rejected, (state, action) => {
      state.login = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.login = action.payload;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.login = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchAllJerseys.fulfilled, (state, action) => {
      state.jerseysList = action.payload;
    });
    builder.addCase(fetchAllJerseys.rejected, (state, action) => {
      state.jerseysList = action.payload;
      ToastNotifyError(action.error.message);
    });
  },
});

const { reducer } = authenticationSlice;
const selectorAuthentication = (state) => state.authentication;
const {
  updatelogin,
  updateScoreBoard,
} = authenticationSlice.actions;
export {
  updatelogin,
  updateScoreBoard,
  selectorAuthentication,
};

export default reducer;
